import React from "react";

const tableStyles = {
    table: "table-auto w-full text-center text-sm border-collapse border border-gray-300",
    thead: "bg-green-700 text-white",
    th: "border border-gray-300 px-4 py-2",
    td: "border border-gray-300 px-4 py-2",
};

const RecommendationRepotView = () => {
    const userData = {
        company: "ABC Pvt Ltd",
        discom: "XYZ Discom",
        location: "Mumbai",
        consumerNumber: "1234567890",
    };

    const consumerData = {
        plantLocation: { area: "Area 51", town: "Townsville" },
        business: "Renewable Energy Solutions",
        connectivityVoltage: "11 kV",
        avgConsumptionPerMonth: "1200 Units",
        avgDiscomVariableCost: "5.5 Rs/unit",
        totalAnnualConsumption: "14400 Units (kWh)",
        totalAnnualEnergyBill: "79,200 Rs",
    };

    const assumptions = [
        { field: "Generator Tariff (Rs/Unit)", groupCaptiveSolar: "4.5", rtsCapex: "5.0" },
        { field: "Rooftop Area (Sq metre)", groupCaptiveSolar: "500", rtsCapex: "300" },
        { field: "Tenure of Contract (Years)", groupCaptiveSolar: "10", rtsCapex: "5" },
        { field: "NPV Discount Rate (%)", groupCaptiveSolar: "14%", rtsCapex: "12%" },
        { field: "Total Capex (Rs Crs/MW)", groupCaptiveSolar: "5", rtsCapex: "4" },
        { field: "Equity Investment (Rs/MWac)", groupCaptiveSolar: "1.5", rtsCapex: "1.2" },
        { field: "Security Deposit", groupCaptiveSolar: "Billing Cycle + Payment Cycle + 10 days", rtsCapex: "NA" },
    ];

    const recommendations = [
        { basis: "Optimum Capacity (kWac)", groupCaptiveSolar: "500", rtsCapex: "300" },
        { basis: "Replaced Units (Units)", groupCaptiveSolar: "600,000", rtsCapex: "400,000" },
        { basis: "Total Annual Consumption (Units)", groupCaptiveSolar: "14400", rtsCapex: "12000" },
        { basis: "Replaced % of Total Consumption (%)", groupCaptiveSolar: "80%", rtsCapex: "60%" },
        { basis: "Annualised Monthly Savings (Rs)", groupCaptiveSolar: "50,000", rtsCapex: "30,000" },
        { basis: "Per Unit Savings (Rs / Unit)", groupCaptiveSolar: "2.5", rtsCapex: "2.0" },
        { basis: "Tons of CO2 Saved (tCO2e)", groupCaptiveSolar: "100", rtsCapex: "80" },
        { basis: "Total Cost (Rs)", groupCaptiveSolar: "50,00,000", rtsCapex: "30,00,000" },
        { basis: "Payback Period (Months)", groupCaptiveSolar: "60", rtsCapex: "48" },
        { basis: "NPV (Rs)", groupCaptiveSolar: "10,00,000", rtsCapex: "8,00,000" },
    ];

    const groupCaptiveNotes = `
    As per MERC regulations, cross subsidy surcharge and Additional Surcharge becomes zero when power procurement is under captive mode of transaction. 
    To be eligible for captive mode transactions, two essential criteria are as follows:
    1) Consumer’s investment to be minimum 26% equity share of generator’s plant cost. The exact quantum of equity commitment needs to be finalized with the identified generator.
    2) Consumer has to consume minimum 51% power generated from generator plant in financial year.
    While the savings on a per unit basis from wind power is high, generation and consumption should match for optimization of the capacity where highest savings occur.
  `;

    const conclusion = `
    Based on the above analysis, the next steps involve finalizing the agreement terms, confirming the generator's plant capacity, and ensuring compliance with MERC regulations.
  `;

    return (
        <div>
            {/* Cover Page */}
            <div
                className="recommendation-report bg-white mx-auto shadow-lg mb-8"
                style={{
                    width: "210mm", // A4 width
                    height: "297mm", // A4 height
                    boxSizing: "border-box",
                    overflow: "hidden",
                    pageBreakAfter: "always",
                    position: "relative", // Added for positioning the watermark
                }}
            >
                {/* Watermark */}
                <div
                    style={{
                        position: "absolute",
                         // Adjust as needed
                        top: "15mm",
                        left: "50%",
                        transform: "translateX(-50%)",
                        backgroundImage: "url('/AmpexLogo.svg')", // Replace with the actual path to your logo
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "250px 250px", // Adjust size as needed
                        width: "250px",
                        height: "250px",
                        opacity: 0.8, // Adjust transparency
                        zIndex: 0,
                    }}
                ></div>

                <div
                    style={{
                        height: "60%",
                        backgroundColor: "#28a745", // Tailwind's green-800 equivalent
                        display: "flex",
                        justifyContent: "center",
                        
                        alignItems: "center",
                        color: "white",
                        flexDirection: "column",
                    }}
                >
                    <h1 style={{
                        marginTop: "20mm",
                    }} className="text-[72px]  text-center font-bold">Recommendation Report</h1>
                </div>
                <div
                    style={{
                        height: "40%",
                        backgroundColor: "white",
                        padding: "20mm",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}
                >
                    <p className="text-lg"><strong>Company:</strong> {userData.company}</p>
                    <p className="text-lg"><strong>Discom:</strong> {userData.discom}</p>
                    <p className="text-lg"><strong>Location:</strong> {userData.location}</p>
                    <p className="text-lg"><strong>Consumer Number:</strong> {userData.consumerNumber}</p>
                </div>
            </div>

            {/* Page 1 */}
            <div
                className="recommendation-report bg-white mx-auto shadow-lg mb-8"
                style={{
                    width: "210mm", // A4 width
                    height: "297mm", // A4 height
                    padding: "20mm", // Increased padding
                    boxSizing: "border-box",
                    border: "1px solid #ddd",
                    fontFamily: "Arial, sans-serif",
                    overflow: "hidden",
                    pageBreakAfter: "always",
                }}
            >
                {/* Content Layer */}
                <div className="relative z-10">
                    <h1 className="text-4xl font-bold text-center text-green-600 mb-6">
                        Recommendation Report
                    </h1>

                    {/* Consumer Information */}
                    {/* <section className="mb-6">
                        <div className="grid grid-cols-2 gap-4 text-lg">
                            <p><strong>Company:</strong> {userData.company}</p>
                            <p><strong>Discom:</strong> {userData.discom}</p>
                            <p><strong>Location:</strong> {userData.location}</p>
                            <p><strong>Consumer Number:</strong> {userData.consumerNumber}</p>
                        </div>
                        <hr className="h-1 my-4 bg-green-400" />
                    </section> */}

                    {/* Consumer Data */}
                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Consumer Data</h2>
                        <div className="relative overflow-x-auto border border-gray-300 rounded-lg">
                            {/* Watermark */}
                            <div
                                className="absolute inset-0"
                                style={{
                                    backgroundImage: "url('/AmpexLogo.svg')", // Replace with the actual path to your logo
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "400px 400px", // Adjust size as needed
                                    backgroundPosition: "center",
                                    opacity: 0.4,
                 
                 
                 
                 
                                    zIndex: 0,
                                }}
                            ></div>
                            <table className={`${tableStyles.table} relative z-10`}>
                                <thead className={tableStyles.thead}>
                                    <tr>
                                        <th className={tableStyles.th}>Field</th>
                                        <th className={tableStyles.th}>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className={tableStyles.td}>Plant Location</td>
                                        <td className={tableStyles.td}>
                                            {consumerData.plantLocation.area}, {consumerData.plantLocation.town}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={tableStyles.td}>Business</td>
                                        <td className={tableStyles.td}>{consumerData.business}</td>
                                    </tr>
                                    <tr>
                                        <td className={tableStyles.td}>Connectivity Voltage</td>
                                        <td className={tableStyles.td}>{consumerData.connectivityVoltage}</td>
                                    </tr>
                                    <tr>
                                        <td className={tableStyles.td}>Avg Consumption per Month</td>
                                        <td className={tableStyles.td}>{consumerData.avgConsumptionPerMonth}</td>
                                    </tr>
                                    <tr>
                                        <td className={tableStyles.td}>Avg Discom Variable Cost</td>
                                        <td className={tableStyles.td}>{consumerData.avgDiscomVariableCost}</td>
                                    </tr>
                                    <tr>
                                        <td className={tableStyles.td}>Total Annual Consumption</td>
                                        <td className={tableStyles.td}>{consumerData.totalAnnualConsumption}</td>
                                    </tr>
                                    <tr>
                                        <td className={tableStyles.td}>Total Annual Energy Bill</td>
                                        <td className={tableStyles.td}>{consumerData.totalAnnualEnergyBill}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Assumptions</h2>
                        <div className="relative overflow-x-auto border border-gray-300 rounded-lg">
                            {/* Watermark */}
                            <div
                                className="absolute inset-0"
                                style={{
                                    backgroundImage: "url('/AmpexLogo.svg')", // Replace with the actual path to your logo
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "400px 400px", // Adjust size as needed
                                    backgroundPosition: "center",
                                    opacity: 0.4,
                 
                 
                 
                 
                                    zIndex: 0,
                                }}
                            ></div>
                            <table className={`${tableStyles.table} relative z-10`}>
                                <thead className={tableStyles.thead}>
                                    <tr>
                                        <th className={tableStyles.th}>Assumptions (Units)</th>
                                        <th className={tableStyles.th}>Group Captive Solar</th>
                                        <th className={tableStyles.th}>RTS Capex</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {assumptions.map((item, index) => (
                                        <tr key={index}>
                                            <td className={tableStyles.td}>{item.field}</td>
                                            <td className={tableStyles.td}>{item.groupCaptiveSolar}</td>
                                            <td className={tableStyles.td}>{item.rtsCapex}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </section>
                </div>
            </div>

            {/* Page 2 */}
            <div
                className="recommendation-report bg-white mx-auto shadow-lg"
                style={{
                    width: "210mm", // A4 width
                    height: "297mm", // A4 height
                    padding: "20mm", // Increased padding
                    boxSizing: "border-box",
                    border: "1px solid #ddd",
                    fontFamily: "Arial, sans-serif",
                    overflow: "hidden",
                }}
            >
                {/* Content Layer */}
                <div className="relative z-10">
                    {/* Recommendation Table */}
                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Recommendation</h2>
                        <div className="relative overflow-x-auto border border-gray-300 rounded-lg">
                            {/* Watermark */}
                            <div
                                className="absolute inset-0"
                                style={{
                                    backgroundImage: "url('/AmpexLogo.svg')", // Replace with the actual path to your logo
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "400px 400px", // Adjust size as needed
                                    backgroundPosition: "center",
                                    opacity: 0.4,
                 
                 
                 
                 
                                    zIndex: 0,
                                }}
                            ></div>
                            <table className={`${tableStyles.table} relative z-10`}>
                                <thead className={tableStyles.thead}>
                                    <tr>
                                        <th className={tableStyles.th}>Basis for Selection</th>
                                        <th className={tableStyles.th}>Group Captive Solar</th>
                                        <th className={tableStyles.th}>RTS Capex</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {recommendations.map((item, index) => (
                                        <tr key={index}>
                                            <td className={tableStyles.td}>{item.basis}</td>
                                            <td className={tableStyles.td}>{item.groupCaptiveSolar}</td>
                                            <td className={tableStyles.td}>{item.rtsCapex}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </section>

                    {/* Group Captive Notes */}
                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Group Captive Notes</h2>
                        <p className="text-md text-gray-700 leading-relaxed">{groupCaptiveNotes}</p>
                    </section>

                    {/* Conclusion */}
                    <section>
                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Conclusion and Next Steps</h2>
                        <p className="text-md text-gray-700 leading-relaxed">{conclusion}</p>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default RecommendationRepotView;