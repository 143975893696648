import React from "react";

const Abutton = ({ text, Mheight, Mwidth, DHeight, DWidth, handleOnclick,disabled }) => {
    console.log({disabled});
  return (
    <button
    style={{ backgroundColor: disabled ? "#B8B8B8" : "#067C4E" }}
      disabled={disabled}
      onClick={handleOnclick}
      className={` text-center rounded-[10px]   p-[10px] text-[16px] font-medium leading-[24px] text-white  h-[${Mheight}px] w-[${Mwidth}px] md:h-[${DHeight}]px md:w-[${DWidth}px] `}
    >
      {text}
    </button>
  );
};

export default Abutton;
