"use client";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpFromBracket,
  faCircleDown,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createUser, sendOTP } from "../../services/apis/user";
import SignUpPopup from "../../components/popup/SignUpPopUp";
import { useLocation } from "react-router-dom";
import { postData } from "../../api/apiDataExchange";

const SignUp = () => {
  const [openPopup, setOpenPopup] = useState(false);
  const [affirmation, setAffirmation] = useState(false);
  const [userId, setUserId] = useState("");
  const [isUserInvited, setIsUserInvited] = useState(false);
  const [orgId, setOrgId] = useState();
  const location = useLocation();

  const [formData, setFormData] = useState({
    username: "",
    companyName: "",
    PAN: "",
    GST: "",
    designation: "",
    email: "",
    website: "",
    mobile: "",
    landline: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({});

  const handleOpenPopup = () => {
    setOpenPopup(!openPopup);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const checkIsInvited = queryParams.get("invited");
    const userEmail = queryParams.get("email");
    const orgId = queryParams.get("orgId");

    if (checkIsInvited === "true") {
      setIsUserInvited(true);
    }
    if (orgId) {
      setOrgId(orgId);
    }
    if (userEmail) {
      setFormData({
        ...formData,
        email: userEmail,
      });
    }
  }, [location.search]);

  useEffect(() => {
    const getdata = async () => {
      const payload = {
        id: orgId,
      };
      const response = await postData(
        `${process.env.REACT_APP_API_URL}/organisation/list`,
        payload
      );

      if (response?.data?.data?.results?.length > 0) {
        console.log({ orgData: response?.data?.data?.results });

        setFormData({
          ...formData,
          companyName: response?.data?.data?.results[0].name,
        });
      }
    };
    getdata();
  }, [orgId]);
  const handleChange = async (e) => {
    const { name, value } = e.target;
    let newErrors = { ...errors };
    setFormData({
      ...formData,
      [name]: value,
    });
    // }
    setErrors(newErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let newErrors = {};

    if (!formData.username.trim()) {
      newErrors.username = "Name is required";
    }
    if (!formData.companyName.trim()) {
      newErrors.companyName = "Company Name is required";
    }
 
    if (!formData.email.trim()) {
      newErrors.email = "Company Email is required";
    }

    if (!formData.mobile.trim()) {
      newErrors.mobile = "Mobile Number is required";
    }

    if (!formData.password.trim()) {
      newErrors.password = "Password is required";
    }
    if (!formData.confirmPassword.trim()) {
      newErrors.confirmPassword = "Confirm Password is required";
    }

    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Password and confirm Password  is Different";
    }

    setErrors(newErrors);
    console.log(newErrors);
    if (Object.keys(newErrors).length === 0) {
      let payload;
      if (isUserInvited) {
        payload = {
          ...formData,
          status: "active",
          isUserInvited,
          userType: ["user"],
          orgId,
        };
      } else {
        payload = {
          ...formData,
          status: "active",
          isUserInvited,
          userType: ["admin"],
        };
      }

      try {
        const response = await createUser(payload);

        if (response.success) {
          // setFormData({
          //     username: "",
          //     companyName: "",
          //     designation: "",
          //     email: "",
          //     website: "",
          //     mobile: "",
          //     landline: "",
          //     password: "",
          //     confirmPassword: "",
          //     photo: null,
          //     companyId: null,
          //     govtId: null
          // })
          setUserId(response?.result?._id);
          setErrors({});
          setOpenPopup(true);

          try {
            const otpResponse = await sendOTP(response?.result?._id);
            if (otpResponse.success) {
              toast.success("OTP Sent.", {
                position: "top-center",
              });
            } else {
              console.error("Error sending OTP:", otpResponse.error);
            }
          } catch (error) {
            console.error("Error sending OTP:", error);
          }
        } else {
          if (response["response"]["data"]["error"]["message"]) {
            console.error(
              "Error creating user:",
              response["response"]["data"]["error"]["message"]
            );
            toast.error(response["response"]["data"]["error"]["message"], {
              position: "top-center",
            });
          }
        }
      } catch (error) {
        console.error("Error creating user:", error);
        toast.error("Error creating user. Please try again later.", {
          position: "top-center",
        });
      }
    } else {
      console.log("Form submission failed. Please fix the errors.");
      // toast.error('Please fix the form errors before submitting.', {
      //     position:"top-center"
      // });
    }
  };

  return (
    <>
      <div className="max-w-[1800px] m-auto ">
        <div className="mt-[  ] md:mt-[27px] sm:mx-[20px]  xl:mx-[177px] pb-[32px] md:pb-[3rem] xl:mt-[68px] bg-[#E2F5EC] rounded-[20px] shadow-md flex  justify-center ">
          <div className="w-full p-4 sm:p-0">
            <div
              id="titleText"
              className="text-[#067C4E] mt-[31px]   md:mt-[38px] xl:mt-[41px] text-[24px] font-medium leading-[36px] text-center"
            >
              Be a Part of Us
            </div>
            <div className="text-[14px] font-normal leading-snug text-center text-black">
              Join Us and Be a Part of Something Green!
            </div>

            <div className="mt-[32px] w-full md:px-[115px] xl:px-[184px]">
              <div className="text-black  text-[14px] leading-[21px] font-normal ml-[10px]">
                Name<span className="text-red-600"> *</span>
              </div>
              <div className="mt-[12px]">
                <input
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  placeholder="Enter Name"
                  className="input-placeholder w-full border pl-[20px] rounded-[10px] h-[50px] border-[#B9B9B9]"
                  type="text"
                />
                <br />
                {errors.username && (
                  <p className="text-red-500 m-[10px] mt-[1px] absolute">
                    {errors.username}
                  </p>
                )}
              </div>
            </div>
            <div className={` mt-[32px] w-full md:px-[115px] xl:px-[184px]`}>
              <div className="text-black  text-[14px] leading-[21px] font-normal ml-[10px]">
                Company Name<span className="text-red-600"> *</span>
              </div>
              <div className="mt-[12px]">
                <input
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                  placeholder="Enter Company Name"
                  disabled={isUserInvited}
                  className="input-placeholder w-full border pl-[20px] rounded-[10px] h-[50px] border-[#B9B9B9]"
                  type="text"
                />
                <br />
                {errors.companyName && (
                  <p className="text-red-500 m-[10px] mt-[1px] absolute">
                    {errors.companyName}
                  </p>
                )}
              </div>
            </div>
            <div className="md:flex md:px-[115px] md:gap-[50px] xl:px-[184px]">
              <div className="mt-[32px] w-full">
                <div className="text-black  text-[14px] leading-[21px] font-normal ml-[10px]">
                  Company Email<span className="text-red-600"> *</span>
                </div>
                <div className="mt-[12px]">
                  <input
                    name="email"
                    value={formData.email}
                    placeholder="Enter Company Email"
                    className="input-placeholder w-full border pl-[20px]  rounded-[10px] h-[50px] border-[#B9B9B9]"
                    type="email"
                    disabled={isUserInvited}
                    onChange={handleChange}
                  />
                  <br />
                  {errors.email && (
                    <p className="text-red-500 m-[10px] mt-[1px] absolute">
                      {errors.email}
                    </p>
                  )}
                </div>
              </div>

              <div className="mt-[32px] w-full ">
                <div className="text-black  text-[14px] leading-[21px] font-normal ml-[10px]">
                  Mobile<span className="text-red-600"> *</span>
                </div>
                <div className="mt-[12px] mobile-input">
                  <input
                    name="mobile"
                    value={formData.mobile}
                    placeholder="Enter Mobile Number"
                    className="input-placeholder w-full border pl-[20px]  rounded-[10px] h-[50px] border-[#B9B9B9] no-spinner"
                    type="number"
                    onChange={handleChange}
                  />
                  {errors.mobile && (
                    <p className="text-red-500 m-[10px] mt-[1px] absolute">
                      {errors.mobile}
                    </p>
                  )}
                </div>
              </div>
            </div>
            
            <div className="md:flex md:px-[115px] md:gap-[50px] xl:px-[184px]">
              <div className="mt-[32px] w-full">
                <div className="text-black text-[14px] leading-[21px] font-normal ml-[10px]">
                  Password<span className="text-red-600"> *</span>
                </div>
                <div className="mt-[12px]">
                  <input
                    name="password"
                    placeholder="Enter Password"
                    className="input-placeholder w-full border pl-[20px]  rounded-[10px] h-[50px] border-[#B9B9B9]"
                    type="password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                  {errors.password && (
                    <p className="text-red-500 m-[10px] mt-[1px] absolute">
                      {errors.password}
                    </p>
                  )}
                </div>
              </div>
              <div className="mt-[32px] w-full">
                <div className="text-black  text-[14px] leading-[21px] font-normal ml-[10px]">
                  Confirm Password<span className="text-red-600"> *</span>
                </div>
                <div className="mt-[12px]">
                  <input
                    name="confirmPassword"
                    placeholder="Enter Password Again"
                    className="input-placeholder w-full border pl-[20px] rounded-[10px] h-[50px] border-[#B9B9B9]"
                    type="password"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                  />
                  {errors.confirmPassword && (
                    <p className="text-red-500 m-[10px] mt-[1px] absolute">
                      {errors.confirmPassword}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-[32px] flex justify-center">
              <button
                onClick={handleSubmit}
                disabled={Object.keys(errors).length > 0} 
                className={`h-[44px] rounded-[100px] py-[10px] px-[25px] text-white text-[16px] leading-[24px] ${
                "bg-[#067C4E]"
                }`}
              >
                Sign Up
              </button>
            </div>
          </div>
        </div>
      </div>
      {openPopup && (
        <SignUpPopup
          setOpenPopup={setOpenPopup}
          openPopUp={openPopup}
          userId={userId}
          handleOpenPopup={handleOpenPopup}
        />
      )}
      <ToastContainer position="top-center" autoClose={3000} />
    </>
  );
};

export default SignUp;
