// src/Routes.js
import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Home from "../pages/home/Home";
import MainLayout from "../layouts/mainLayout/MainLayout";
import NotFound from "../pages/notFound/NotFound";
import { useAuth } from "../hooks/useAuth";
import ProtectedRoute from "./ProtectedRoute";
import Dashboard from "../pages/dashboard/Dashboard";
import Analytics from "../pages/analytics/Analytics";
import AddOrganisation from "../pages/organisation/AddOrganisation";
import EditOrganisation from "../pages/organisation/EditOrganisation";
import ViewOrganisation from "../pages/organisation/ViewOrganisation";
import Login from "../pages/login/Login";
import Test from "../pages/Test";
import Upload from "../pages/upload/Upload";
import ViewUnit from "../pages/unit/ViewUnit";
import ComparisonView from "../pages/comparison/ComparisonView";
import AnalyticsTest from "../pages/analytics/AnalaticsTest";
import PowerConsumption from "../pages/power-consumption/PowerConsumption";
import Demo from "../pages/demo/Demo";
import AddUnit from "../pages/unit/AddUnit";
import AddMeter from "../pages/meter/AddMeter";
import AddFactory from "../pages/factory/AddFactory";
import MyData from "../pages/myData/MyData";
import EditFactory from "../pages/factory/EditFactory";
import EditUnit from "../pages/unit/EditUnit";
import EditMeter from "../pages/meter/EditMeter";
import ViewFactory from "../pages/factory/ViewFactory";
import ViewMeter from "../pages/meter/ViewMeter";
import MyProfile from "../pages/myProfile/MyProfile";
import SignUp from "../pages/signup/Signup";
import ForgotPassword from "../pages/forgotPassword/ForgotPassword";
import SetNewPassword from "../pages/setNewPassword/SetNewPassword";
import CompareOcrBills from "../pages/comparisonOcr/CompareOcrBills"
import ValidateBillsOcrData from "../pages/comparisonOcr/ValidateBillsOcrData";
import UpdateUnitSetting from "../pages/updateUnitSetting/UpdateUnitSetting";
import MeterList from "../pages/meterList/UnitList";
import UnitList from "../pages/meterList/UnitList";
import StateList from "../pages/stateList/StateList";
import DiscomList from "../pages/discomList/DiscomList"
import CustomisedData from "../pages/charts/customisedData/CustomisedData";
import FutureLoad from "../pages/charts/futureLoad/FutureLoad";
import CustomisedDataVisulization from "../views/CustomisedDataview/customisedDataVisualization/CustomisedDataVisulization";
import FutureLoadSimulationView from "../views/FutureLoadSimulationView";
import AggregatedAndComparisonView from "../views/AggregatedAndComparisonView";
import BillConsumptionView from "../views/BillConsumptionView";
import Recommendation from "../pages/recommendation/Recommendation";
import RecommendationNew from "../pages/recommendation/RecommendationNew";
import ManageUsers from "../pages/manageUsers/ManageUsers";
import NoAccessPage from "../pages/noaccess/NoAccess";
import UserRoleManagement from "../pages/accessRole/AccessRole";
import ContactAdministrator from "../pages/ContactAdministrator";
import IncentiveDetails from "../pages/Incentive/IncentiveDetails";
import AddNewUser from "../pages/addNewUser/AddNewUser";
import RecommendationList from "../pages/recommendation/RecommendationList";
import Notifications from "../pages/notifications/Notifications";
import PxAnalytics from "../pages/px/PxAnalytics";
import PxAnalyticsListing from "../pages/px/PxAnalyticsListing";
import RecommendationReport from "../components/report/RecommendationReport";

function AppRoutes() {
  const { user, isAuthenticated, login, logout } = useAuth();

  // console.log(isAuthenticated);
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Dashboard />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Dashboard />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/my-profile"
          element={
            <ProtectedRoute>
              <MainLayout>
                <MyProfile />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
        path="/px-analytics"
        element={
          <ProtectedRoute>
            <MainLayout>
              <PxAnalyticsListing />
            </MainLayout>
          </ProtectedRoute>
        }
      />
          <Route
        path="/px-analytics/:id"
        element={
          <ProtectedRoute>
            <MainLayout>
              <PxAnalytics />
            </MainLayout>
          </ProtectedRoute>
        }
      />

        
        <Route
          path="/add-organization"
          element={
            <ProtectedRoute>
              <MainLayout>
                <AddOrganisation />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/userrole"
          element={
            <ProtectedRoute>
              <MainLayout>
                <UserRoleManagement />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/edit-organisation/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <EditOrganisation />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/edit-factory/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <EditFactory />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/edit-unit/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <EditUnit />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/compare-ocr-bills/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <ValidateBillsOcrData />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/recommendation"
          element={
            <ProtectedRoute>
              <MainLayout>
                <RecommendationNew />
              </MainLayout>
            </ProtectedRoute>
          }
        />

<Route
          path="/recommendation/report/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <RecommendationReport />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/edit-meter/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <EditMeter />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/view-organisation/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <ViewOrganisation />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/view-factory/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <ViewFactory />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/view-unit/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <ViewUnit />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/view-meter/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <ViewMeter />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/add-meter"
          element={
            <ProtectedRoute>
              <MainLayout>
                <AddMeter />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/analytics"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Analytics />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/analytics/customisedata"
          element={
            <ProtectedRoute>
              <MainLayout>
                <CustomisedData />
              </MainLayout>
            </ProtectedRoute>
          }
        />
                <Route
          path="/customisedataview"
          element={
            <ProtectedRoute>
              <MainLayout>
                <CustomisedDataVisulization />
              </MainLayout>
            </ProtectedRoute>
          }
        />


        <Route
          path="/analytics/futuredata"
          element={
            <ProtectedRoute>
              <MainLayout>
                <FutureLoad />
              </MainLayout>
            </ProtectedRoute>
          }
        />

<Route
          path="/analytics/futuredata-view"
          element={
            <ProtectedRoute>
              <MainLayout>
              <FutureLoadSimulationView />
              </MainLayout>
            </ProtectedRoute>
          }
        />

<Route
          path="/analytics/aggregated-view"
          element={
            <ProtectedRoute>
              <MainLayout>
              <AggregatedAndComparisonView />
              </MainLayout>
            </ProtectedRoute>
          }
        />



        <Route
          path="/unit-list"
          element={
            <ProtectedRoute>
              <MainLayout>
                <UnitList />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        {/* <Route
          path="/state-list"
          element={
            <ProtectedRoute>
              <MainLayout>
                <StateList />
              </MainLayout>
            </ProtectedRoute>
          }
        /> */}
        {/* <Route
          path="/discom-list"
          element={
            <ProtectedRoute>
              <MainLayout>
                <DiscomList />
              </MainLayout>
            </ProtectedRoute>
          }
        /> */}
        <Route
          path="/analytics/test"
          element={
            <ProtectedRoute>
              <MainLayout>
                <AnalyticsTest />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/test"
          element={
            <MainLayout>
              <Test />
            </MainLayout>
          }
        />

        {/* <Route path="/login" element={isAuthenticated ? <Navigate to="/" /> : <Login />} /> */}
        <Route path="/login" element={<Login />} />

        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/set-new-password" element={<SetNewPassword />} />
        <Route path="/signup" element={<SignUp />} />
        <Route
          path="/upload"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Upload />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/unit/view"
          element={
            <ProtectedRoute>
              <MainLayout>
                <ViewUnit />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/incentive-details"
          element={
            <ProtectedRoute>
              <MainLayout>
                <IncentiveDetails />
              </MainLayout>
            </ProtectedRoute>
          }
        />
            <Route
          path="/notifications"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Notifications />
              </MainLayout>
            </ProtectedRoute>
          }
        />
            <Route
          path="/add-new-user"
          element={
            <ProtectedRoute>
              <MainLayout>
                <AddNewUser />
              </MainLayout>
            </ProtectedRoute>
          }
        />

<Route
          path="/recommendation-list"
          element={
            <ProtectedRoute>
              <MainLayout>
                <RecommendationList />
              </MainLayout>
            </ProtectedRoute>
          }
        />


        <Route
          path="/comparison/view"
          element={
            <ProtectedRoute>
              <MainLayout>
                <ComparisonView />
              </MainLayout>
            </ProtectedRoute>
          }
        />


        <Route
          path="/no-access"
          element={
            <ProtectedRoute>
              <MainLayout>
                <NoAccessPage />
              </MainLayout>
            </ProtectedRoute>
          }
        />


        <Route
          path="/contact-admin"
          element={
            <ProtectedRoute>
              <MainLayout>
                <ContactAdministrator />
              </MainLayout>
            </ProtectedRoute>
          }
        />


        <Route
          path="/compare-ocr-bills"
          element={
            <ProtectedRoute>
              <MainLayout>
                <CompareOcrBills />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/unit/add"
          element={
            <ProtectedRoute>
              <MainLayout>
                <AddUnit />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/meter/add"
          element={
            <ProtectedRoute>
              <MainLayout>
                <AddMeter />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/factory/add"
          element={
            <ProtectedRoute>
              <MainLayout>
                <AddFactory />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/power-consumption"
          element={
            <ProtectedRoute>
              <MainLayout>
                <PowerConsumption />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/demo"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Demo />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/manage-users"
          element={
            <ProtectedRoute>
              <MainLayout>
                <ManageUsers />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/update-unit-setting/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <UpdateUnitSetting />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/my-data"
          element={
            <ProtectedRoute>
              <MainLayout>
                <MyData />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default AppRoutes;
