import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import GoTODashboard from "../../components/button/GoTODashboard";
import BackButton from "../../components/backButton/BackButton";
import RecommendationRepotView from "./RecommendationRepotView";
// import { jsPDF } from "jspdf";
// import html2canvas from "html2canvas";

const RecommendationReport = () => {
    // const downloadPDF = async () => {
    //     const reportElement = document.getElementById("recommendation-report");
    //     const canvas = await html2canvas(reportElement, { scale: 2 });
    //     const imgData = canvas.toDataURL("image/png");
        
    //     const pdf = new jsPDF("p", "mm", "a4");
    //     const pdfWidth = pdf.internal.pageSize.getWidth();
    //     const pdfHeight = pdf.internal.pageSize.getHeight();
      
    //     // Calculate the height of the image in the PDF
    //     const imgHeight = (canvas.height * pdfWidth) / canvas.width;
        
    //     let heightLeft = imgHeight;
    //     let position = 0;
      
    //     // Add the first page
    //     pdf.addImage(imgData, "PNG", 0, position, pdfWidth, imgHeight);
    //     heightLeft -= pdfHeight;
      
    //     // Add extra pages if needed
    //     while (heightLeft > 0) {
    //       position = heightLeft - imgHeight;
    //       pdf.addPage();
    //       pdf.addImage(imgData, "PNG", 0, position, pdfWidth, imgHeight);
    //       heightLeft -= pdfHeight;
    //     }
      
    //     pdf.save("Recommendation_Report.pdf");
    //   };
      
  return (
    <div className="max-w-[1920px] relative m-auto mt-[70px] sm:mt-[100px] pb-2">
      <div className="hidden sm:block">
        <Sidebar type={2} />
      </div>
      <div
        className={`ml-[10px] sm:ml-[80px] sm:px-[50px] px-[15px] transition-width duration-300`}
      >
        <div className={`sm:hidden my-5 flex items-center justify-end w-full gap-2 right-0 px-[15px]`}>
          <GoTODashboard /> <BackButton py={"py-1"} px={"px-1"} />
        </div>
        <div className="flex w-full items-center justify-between">
    
          <div
            className={`hidden sm:flex  my-5 justify-end sm:justify-end items-center w-full gap-4 `}
          >
            <GoTODashboard />
            <BackButton  />
          </div>
        </div>
        <div className="relative overflow-hidden shadow-md sm:rounded-lg">
          {/* Add an ID to the report container for PDF generation */}
          <div id="recommendation-report">
            <RecommendationRepotView />
          </div>
        </div>
        {/* Button to download the report */}
        <div className="mt-4 flex justify-end">
          {/* <button
            onClick={downloadPDF}
            className="bg-green-600  text-white px-4 py-2 rounded shadow hover:bg-green-700"
          >
            Download PDF
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default RecommendationReport;